import * as Sentry from "@sentry/vue";
import api from "~/api";
interface ImageDimensions {
  width: number;
  height: number;
  ratio: number;
}

class ImageError extends Error {
  constructor(message: string, public code: string) {
    super(message);
    this.name = 'ImageError';
  }
}

// 图片尺寸限制 
const IMAGE_CONSTRAINTS = {
  MIN_DIMENSION: 160,
  MAX_DIMENSION: 4000,
  MIN_RATIO: 9 / 21,
  MAX_RATIO: 21 / 9
} as const;
// GIF图支持最大秒数
const MAX_GIF_DURATION = 15 * 1000;



export function useUtils() {
  const snackbar = useSnackbarStore();
  const authStore = useAuthStore();
  const comm = useCommStore();
  const router = useRouter();
  const globalLoading = useLoadingStore();
  const { $logError }: any = useNuxtApp();

  const download = async (url: string, fileName: string = "") => {
    try {
      globalLoading.startLoading("waiting...");
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok: ${response.statusText} (${response.status})`
        );
      }

      const totalSize = Number(response.headers.get("content-length"));
      let loaded = 0;

      const reader = response.body?.getReader();
      if (!reader) throw new Error("ReadableStream is not supported");

      const stream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            loaded += value.length;
            // 将获取到的流数据发送给前端
            controller.enqueue(value);

            // 计算并显示下载进度
            const percent = Math.round((loaded / totalSize) * 100);
            globalLoading.startLoading(`${percent}%`);
          }

          controller.close();
          reader.releaseLock();
        },
      });

      const blob = await new Response(stream).blob();

      const link = document.createElement("a");
      const objectURL = window.URL.createObjectURL(blob);
      link.href = objectURL;

      // 使用 URL 对象解析文件名
      const urlObj = new URL(url);
      const defaultFileName = urlObj.pathname.split("/").pop() || "download";

      link.download = fileName || defaultFileName;
      document.body.appendChild(link);
      link.click();

      // Clean up after a slight delay to ensure the download has started
      setTimeout(() => {
        window.URL.revokeObjectURL(objectURL);
        document.body.removeChild(link);
      }, 100); // 100ms 延迟确保下载已开始
    } catch (error) {
      snackbar.showError(`Failed to download file: ${error.message}`);

      // 上报错误
      $logError(error, "Download error");

      console.error("Download error:", error);
    } finally {
      globalLoading.stopLoading();
    }
  };

  const loadScript = (src: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script ${src}`));
      document.head.appendChild(script);
    });
  };

  function loginByToken(token: string, redirectTo?: string) {
    if (!token) return;
    authStore.login(token);
    const route = useRoute();
    console.log("fullPath", route.fullPath);

    if (redirectTo) {
      router.push(redirectTo);
    } else if (route.fullPath === "/login/google") {
      router.push("/my/account");
    }
  }

  async function reportError(
    type: string,
    message: string,
    additionalData: object = {}
  ) {
    try {
      await api.reportExceptions(type, {
        message,
        ...additionalData,
      });
    } catch (error) {
      console.error("Error reporting exception:", error);
    }
  }

  const copyToClipboard = async (text) => {
    try {
      const urlToCopy = text; // 你可以替换为你想要复制的 URL
      await navigator.clipboard.writeText(urlToCopy);
      snackbar.showSuccess("Download link copied");
    } catch (error) {
      console.error("Failed to copy", error);
    }
  };

  // 获取GIF时长
  async function getGifDuration(file: File): Promise<number> {
    const { parseGIF, decompressFrames } = await import('gifuct-js');

    try {
      const arrayBuffer = await file.arrayBuffer();
      const gifData = parseGIF(arrayBuffer);
      const frames = decompressFrames(gifData, true);

      // 计算总时长（毫秒）
      const totalDuration = frames.reduce((sum, frame) => sum + (frame.delay || 0), 0);

      return totalDuration;
    } catch (error) {
      throw new ImageError(`Failed to get GIF duration: ${error.message}`, 'GIF_DURATION_ERROR');
    }
  }

  // 处理GIF
  async function processGif(file: File, targetDimensions: ImageDimensions & {
    contentWidth?: number,
    contentHeight?: number,
    offsetX?: number,
    offsetY?: number
  }): Promise<File> {

    const { parseGIF, decompressFrames } = await import('gifuct-js');
    const { default: GIF } = await import('gif.js.optimized');

    return new Promise(async (resolve, reject) => {
      try {
        if (!targetDimensions.contentWidth) {
          return resolve(file);
        }

        // 读取文件内容
        const arrayBuffer = await file.arrayBuffer();
        const gifData = parseGIF(arrayBuffer);
        const frames = decompressFrames(gifData, true);

        console.log('Total frames:', frames.length);

        const contentWidth = targetDimensions.contentWidth;
        const contentHeight = targetDimensions.contentHeight;
        const offsetX = targetDimensions.offsetX || 0;
        const offsetY = targetDimensions.offsetY || 0;

        console.log('Processing GIF with dimensions:', {
          canvas: { width: targetDimensions.width, height: targetDimensions.height },
          content: { width: contentWidth, height: contentHeight },
          offset: { x: offsetX, y: offsetY }
        });

        // 创建新的 GIF
        const gif = new GIF({
          workers: navigator.hardwareConcurrency || 2,
          quality: 10,
          interlaced: false,
          dither: false,
          width: targetDimensions.width,
          height: targetDimensions.height,
          background: 'rgba(0,0,0,0)',
          workerScript: '/workers/gif.worker.js'
        });


        // 创建画布
        const canvas = document.createElement('canvas');
        canvas.width = targetDimensions.width;
        canvas.height = targetDimensions.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) throw new Error('无法获取 canvas context');

        // 创建临时画布
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        if (!tempCtx) throw new Error('无法获取临时 canvas context');

        let frameImageData;
        let previousCanvas = document.createElement('canvas');
        previousCanvas.width = targetDimensions.width;
        previousCanvas.height = targetDimensions.height;
        const previousCtx = previousCanvas.getContext('2d');
        if (!previousCtx) throw new Error('无法获取上一帧 canvas context');

        // 处理每一帧
        for (const frame of frames) {
          // 根据 disposalType 处理
          if (frame.disposalType === 2) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          } else {
            ctx.drawImage(previousCanvas, 0, 0);
          }

          // 设置帧数据
          if (!frameImageData ||
            frame.dims.width != frameImageData.width ||
            frame.dims.height != frameImageData.height) {
            tempCanvas.width = frame.dims.width;
            tempCanvas.height = frame.dims.height;
            frameImageData = tempCtx.createImageData(frame.dims.width, frame.dims.height);
          }

          frameImageData.data.set(frame.patch);
          tempCtx.putImageData(frameImageData, 0, 0);

          // 计算缩放比例 - 使用内容尺寸而不是填充后的尺寸
          const scaleX = contentWidth / gifData.lsd.width;
          const scaleY = contentHeight / gifData.lsd.height;

          console.log('Scale factors:', {
            scaleX,
            scaleY,
            originalSize: { width: gifData.lsd.width, height: gifData.lsd.height },
            contentSize: { width: contentWidth, height: contentHeight }
          });

          // 绘制当前帧
          ctx.drawImage(
            tempCanvas,
            0, 0,
            frame.dims.width, frame.dims.height,
            offsetX + (frame.dims.left * scaleX),  // 使用缩放后的位置
            offsetY + (frame.dims.top * scaleY),   // 使用缩放后的位置
            frame.dims.width * scaleX,             // 使用缩放后的尺寸
            frame.dims.height * scaleY             // 使用缩放后的尺寸
          );

          // 保存当前帧到 previousCanvas
          previousCtx.clearRect(0, 0, previousCanvas.width, previousCanvas.height);
          previousCtx.drawImage(canvas, 0, 0);

          // 将帧添加到新 GIF
          gif.addFrame(canvas, {
            delay: frame.delay,
            copy: true
          });

          console.log(`Added frame ${frames.indexOf(frame) + 1}/${frames.length}`);
        }

        // 监听进度
        gif.on('progress', (p) => {
          console.log(`GIF 处理进度: ${Math.round(p * 100)}%`);
        });

        // 完成回调
        gif.on('finished', (blob) => {
          const fileName = `${file.name.replace(/\.[^/.]+$/, "")}_processed.gif`;

          // 创建下载链接
          // const link = document.createElement('a');
          const url = URL.createObjectURL(blob);

          // link.href = url;
          // link.download = fileName;

          // // 触发下载
          // document.body.appendChild(link);
          // link.click();

          // 显示提示
          // snackbar.showSuccess(`GIF 处理完成，正在下载: ${fileName}`);

          // 清理
          setTimeout(() => {
            URL.revokeObjectURL(url);
            // document.body.removeChild(link);
          }, 100);

          resolve(new File([blob], fileName, { type: 'image/gif' }));
        });

        // 开始渲染
        gif.render();

      } catch (error) {
        reject(new ImageError(`GIF 处理失败: ${error.message}`, 'GIF_PROCESSING_ERROR'));
      }
    });
  }

  // 1. 获取图片尺寸
  async function getImageDimensions(file: File): Promise<ImageDimensions> {
    const imageUrl = URL.createObjectURL(file);
    try {
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = (e) => reject(new Error('Failed to load image'));
        img.src = imageUrl;
      });

      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const ratio = width / height;

      return { width, height, ratio };
    } finally {
      URL.revokeObjectURL(imageUrl);
    }
  }

  // 计算目标尺寸（处理比例问题）
  function calculateTargetDimensions(dimensions: ImageDimensions): ImageDimensions {
    let { width, height, ratio } = dimensions;

    if (ratio < IMAGE_CONSTRAINTS.MIN_RATIO) {
      height = width / IMAGE_CONSTRAINTS.MIN_RATIO;
      console.warn(`Image ratio ${ratio.toFixed(3)} is too small, adjusting to ${IMAGE_CONSTRAINTS.MIN_RATIO}`);
    } else if (ratio > IMAGE_CONSTRAINTS.MAX_RATIO) {
      width = height * IMAGE_CONSTRAINTS.MAX_RATIO;
      console.warn(`Image ratio ${ratio.toFixed(3)} is too large, adjusting to ${IMAGE_CONSTRAINTS.MAX_RATIO}`);
    }

    return { width, height, ratio: width / height };
  }

  // 4. 转换为PNG（仅负责格式转换）
  async function convertToPNG(file: File) {
    try {
      // 如果已经是PNG，仍需检查比例
      if (file.type === 'image/png') {
        return file;
      }

      // 创建图片URL
      const imageUrl = URL.createObjectURL(file);
      const img = new Image();

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = (e) => reject(new Error('Failed to load image'));
        img.src = imageUrl;
      });

      const canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('Failed to get canvas context');
      }

      ctx.drawImage(img, 0, 0);

      // 转换为PNG
      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error('Failed to create blob'));
          },
          'image/png',
          1  // 最高质量
        );
      });

      // 清理
      URL.revokeObjectURL(imageUrl);

      const originalName = file.name.replace(/\.[^/.]+$/, "");
      return new File([blob], `${originalName}.png`, { type: 'image/png' });

    } catch (error) {
      console.error('Error converting image:', error);
      throw new Error(`Failed to convert image: ${error.message}`);
    }
  }

  // 5. 裁剪图片
  async function cropImage(file: File, targetDimensions: ImageDimensions): Promise<File> {
    const imageUrl = URL.createObjectURL(file);
    try {
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = (e) => reject(new Error('Failed to load image'));
        img.src = imageUrl;
      });

      const canvas = document.createElement('canvas');
      canvas.width = targetDimensions.width;
      canvas.height = targetDimensions.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('Failed to get canvas context');
      }

      // 计算裁剪位置（居中裁剪）
      const sourceX = Math.max(0, (img.naturalWidth - targetDimensions.width) / 2);
      const sourceY = Math.max(0, (img.naturalHeight - targetDimensions.height) / 2);

      ctx.drawImage(
        img,
        sourceX, sourceY,
        targetDimensions.width, targetDimensions.height,
        0, 0,
        targetDimensions.width, targetDimensions.height
      );

      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error('Failed to create blob'));
          },
          'image/png',
          1
        );
      });

      const originalName = file.name.replace(/\.[^/.]+$/, "");
      return new File([blob], `${originalName}_cropped.png`, { type: 'image/png' });

    } catch (error) {
      throw new ImageError(`Failed to crop image: ${error.message}`, 'CROP_ERROR');
    } finally {
      URL.revokeObjectURL(imageUrl);
    }
  }

  // 填充图片到最小尺寸
  async function padImageToMinimum(file: File, dimensions: ImageDimensions): Promise<File> {
    const imageUrl = URL.createObjectURL(file);

    try {
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = (e) => reject(new Error('Failed to load image'));
        img.src = imageUrl;
      });

      // 计算新的尺寸，确保至少达到最小尺寸
      const targetWidth = Math.max(dimensions.width, IMAGE_CONSTRAINTS.MIN_DIMENSION);
      const targetHeight = Math.max(dimensions.height, IMAGE_CONSTRAINTS.MIN_DIMENSION);

      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('Failed to get canvas context');
      }

      ctx.clearRect(0, 0, targetWidth, targetHeight);

      // 计算居中位置
      const x = Math.max(0, (targetWidth - dimensions.width) / 2);
      const y = Math.max(0, (targetHeight - dimensions.height) / 2);

      // 绘制原始图片（居中）
      ctx.drawImage(
        img,
        x, y,
        dimensions.width,
        dimensions.height
      );

      // 转换为PNG
      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error('Failed to create blob'));
          },
          'image/png',
          1  // 最高质量
        );
      });

      const originalName = file.name.replace(/\.[^/.]+$/, "");
      return new File([blob], `${originalName}_padded.png`, { type: 'image/png' });

    } catch (error) {
      throw new ImageError(`Failed to pad image: ${error.message}`, 'PADDING_ERROR');
    } finally {
      URL.revokeObjectURL(imageUrl);
    }
  }

  // 缩放图片到最大尺寸
  async function resizeToMaxDimension(file: File, dimensions: ImageDimensions): Promise<File> {
    const imageUrl = URL.createObjectURL(file);

    try {
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = (e) => reject(new Error('Failed to load image'));
        img.src = imageUrl;
      });

      // 计算缩放比例
      const scaleRatio = Math.min(
        IMAGE_CONSTRAINTS.MAX_DIMENSION / dimensions.width,
        IMAGE_CONSTRAINTS.MAX_DIMENSION / dimensions.height
      );

      // 计算新尺寸（保持比例）
      const targetWidth = Math.floor(dimensions.width * scaleRatio);
      const targetHeight = Math.floor(dimensions.height * scaleRatio);

      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('Failed to get canvas context');
      }

      // 使用高质量的缩放算法
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      // 清除画布并绘制缩放后的图片
      ctx.clearRect(0, 0, targetWidth, targetHeight);
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error('Failed to create blob'));
          },
          'image/png',
          1
        );
      });

      const originalName = file.name.replace(/\.[^/.]+$/, "");
      return new File([blob], `${originalName}_resized.png`, { type: 'image/png' });

    } catch (error) {
      throw new ImageError(`Failed to resize image: ${error.message}`, 'RESIZE_ERROR');
    } finally {
      URL.revokeObjectURL(imageUrl);
    }
  }

  // 处理图片(处理比例、尺寸、格式, 格式：png,webp、jpg、gif，gif需要额外处理)
  async function normalizeImage(file: File): Promise<File> {
    try {

      // 1. 获取图片尺寸
      let dimensions = await getImageDimensions(file);
      console.log('Original dimensions:', dimensions);
      console.log('process.client', process.client);
      const targetDimensions = calculateTargetDimensions(dimensions);

      // 处理gif图片
      if (file.type === 'image/gif') {
        console.log('targetDimensions', targetDimensions);
        console.log('dimensions', dimensions);

        // 检查gif时长
        const duration = await getGifDuration(file);
        console.log('duration', duration);
        if (duration > MAX_GIF_DURATION) {
          throw new ImageError(`GIF duration is too long: ${duration / 1000}s, please reduce to ${MAX_GIF_DURATION / 1000}s`, 'GIF_DURATION_ERROR');
        }

        // 检查是否需要处理
        const needsDownscaling = dimensions.width > IMAGE_CONSTRAINTS.MAX_DIMENSION || dimensions.height > IMAGE_CONSTRAINTS.MAX_DIMENSION;

        // 是否需要最小尺寸
        const needsMinimumSize = dimensions.width < IMAGE_CONSTRAINTS.MIN_DIMENSION || dimensions.height < IMAGE_CONSTRAINTS.MIN_DIMENSION;

        // 是否需要调整比例
        const ratioNeedsAdjustment = dimensions.ratio < IMAGE_CONSTRAINTS.MIN_RATIO || dimensions.ratio > IMAGE_CONSTRAINTS.MAX_RATIO;

        console.log('Checks:', {
          needsDownscaling,
          needsMinimumSize,
          ratioNeedsAdjustment
        });

        if (ratioNeedsAdjustment || needsDownscaling || needsMinimumSize) {
          // 保持原始比例
          const originalRatio = dimensions.ratio;
          let finalWidth = dimensions.width;
          let finalHeight = dimensions.height;
          let canvasWidth, canvasHeight, offsetX = 0, offsetY = 0;

          // 1. 如果需要缩放
          if (needsDownscaling) {
            const scale = Math.min(
              IMAGE_CONSTRAINTS.MAX_DIMENSION / finalWidth,
              IMAGE_CONSTRAINTS.MAX_DIMENSION / finalHeight
            );
            finalWidth = Math.floor(finalWidth * scale);
            finalHeight = Math.floor(finalHeight * scale);
          }

          // 2.  调整比例（通过添加水平或垂直padding）
          //  9:21  8:21
          if (ratioNeedsAdjustment) {
            if (originalRatio < IMAGE_CONSTRAINTS.MIN_RATIO) {
              // 图片太窄，增加宽度
              canvasHeight = finalHeight;
              canvasWidth = Math.ceil(canvasHeight * IMAGE_CONSTRAINTS.MIN_RATIO);
              offsetX = Math.floor((canvasWidth - finalWidth) / 2);
              offsetY = 0;
            } else if (originalRatio > IMAGE_CONSTRAINTS.MAX_RATIO) {
              // 图片太宽，增加高度
              canvasWidth = finalWidth;
              canvasHeight = Math.ceil(canvasWidth / IMAGE_CONSTRAINTS.MAX_RATIO);
              offsetX = 0;
              offsetY = Math.floor((canvasHeight - finalHeight) / 2);
            }
          } else {
            // 比例正常，使用内容尺寸
            canvasWidth = finalWidth;
            canvasHeight = finalHeight;
          }

          // 确保满足最小尺寸要求
          if (canvasWidth < IMAGE_CONSTRAINTS.MIN_DIMENSION) {
            const oldWidth = canvasWidth;
            canvasWidth = IMAGE_CONSTRAINTS.MIN_DIMENSION;
            offsetX += Math.floor((canvasWidth - oldWidth) / 2);
          }
          if (canvasHeight < IMAGE_CONSTRAINTS.MIN_DIMENSION) {
            const oldHeight = canvasHeight;
            canvasHeight = IMAGE_CONSTRAINTS.MIN_DIMENSION;
            offsetY += Math.floor((canvasHeight - oldHeight) / 2);
          }

          const finalDimensions = {
            width: canvasWidth,
            height: canvasHeight,
            ratio: canvasWidth / canvasHeight,
            // 保存原始内容的尺寸和偏移量
            contentWidth: finalWidth,
            contentHeight: finalHeight,
            offsetX,
            offsetY
          };

          console.log('Final GIF dimensions:', finalDimensions);
          return await processGif(file, finalDimensions);
        }
        return file;
      }


      // 2. 计算目标尺寸（处理比例问题）
      if (targetDimensions.width !== dimensions.width ||
        targetDimensions.height !== dimensions.height) {
        console.warn('Adjusting image ratio...');
        file = await cropImage(file, targetDimensions);
        dimensions = await getImageDimensions(file);
        console.log('After ratio adjustment:', dimensions);
      }

      // 3. 如果超出最大尺寸，进行缩放
      if (dimensions.width > IMAGE_CONSTRAINTS.MAX_DIMENSION ||
        dimensions.height > IMAGE_CONSTRAINTS.MAX_DIMENSION) {
        console.warn('Image exceeds maximum dimensions, resizing...');
        file = await resizeToMaxDimension(file, dimensions);
        dimensions = await getImageDimensions(file);
        console.log('After resizing:', dimensions);
      }

      // 4. 如果尺寸太小，进行填充
      if (dimensions.width < IMAGE_CONSTRAINTS.MIN_DIMENSION ||
        dimensions.height < IMAGE_CONSTRAINTS.MIN_DIMENSION) {
        console.warn('Image is smaller than minimum dimensions, padding...');
        file = await padImageToMinimum(file, dimensions);
        dimensions = await getImageDimensions(file);
        console.log('After padding dimensions:', dimensions);
      }

      return await convertToPNG(file);

    } catch (error) {
      if (error instanceof ImageError) {
        throw error;
      }
      throw new ImageError(`Image processing failed: ${error.message}`, 'PROCESSING_ERROR');
    }
  }

  // 获取视频时长
  async function getVideoDuration(file: File): Promise<number> {
    try {
      const { MP4Clip } = await import('@webav/av-cliper');
      try {
        const videoSrc = URL.createObjectURL(file);
        const response = await fetch(videoSrc);

        if (!response.body) {
          throw new Error('Failed to fetch video');
        }

        const clip = new MP4Clip(response.body);
        await clip.ready;
        const meta = clip.meta;
        const duration = meta.duration;

        // Clean up
        URL.revokeObjectURL(videoSrc);
        clip.destroy();

        return duration;
      } catch (mp4ClipError) {
        console.warn('MP4Clip failed, falling back to HTML5 video:', mp4ClipError);

        // Fallback to HTML5 video element
        return new Promise((resolve, reject) => {
          const video = document.createElement('video');
          const videoSrc = URL.createObjectURL(file);

          video.onloadedmetadata = () => {
            const duration = video.duration;
            URL.revokeObjectURL(videoSrc);
            resolve(duration);
          };

          video.onerror = () => {
            URL.revokeObjectURL(videoSrc);
            reject(new Error('Failed to load video metadata'));
          };

          video.src = videoSrc;
        });
      }
    } catch (error) {
      console.error('Error getting video duration:', error);
      throw new Error(`Failed to get video duration: ${error.message}`);
    }
  }

  // 截取视频
  async function trimVideo(videoUrl: string, trimOptions: { startTime: number, endTime: number }): Promise<Blob> {
    try {
      const { MP4Clip, Combinator, OffscreenSprite, fixFMP4Duration } = await import('@webav/av-cliper');
      // 获取视频流
      const response = await fetch(videoUrl);
      if (!response.body) throw new Error('Failed to fetch video');
      const originalSize = parseInt(response.headers.get('content-length') || '0');

      // 创建 MP4Clip 实例
      const clip = new MP4Clip(response.body);
      await clip.ready; // 等待视频准备完成

      // 计算原视频的比特率
      const originalDuration = clip.meta.duration / 1000000; // 转换为秒
      const originalBitrate = (originalSize * 8) / originalDuration; // 转换为 bits/second

      // 使用 split 方法切割视频
      const startTimeUs = Math.max(trimOptions.startTime, 1 * 1000* 1000);
      const [, firstClip] = await clip.split(startTimeUs);
      const endTimeUs = Math.min(trimOptions.endTime, clip.meta.duration);
      const [finalClip] = await firstClip.split(endTimeUs - startTimeUs);

      // 创建 Combinator 实例进行视频合成
      const combinator = new Combinator({
        width: clip.meta.width,
        height: clip.meta.height,
        // fps: 30,
        // bitrate: 5000000,
        // bitrate: originalBitrate,
        bitrate: Math.max(originalBitrate, 1000000),
        audio: true,
        videoCodec: 'avc1.42E01E',

      });

      // 创建 OffscreenSprite 并添加到合成器
      const sprite = new OffscreenSprite(finalClip);
      await combinator.addSprite(sprite, { main: true });

      // 获取输出流
      const outputStream = combinator.output();

      const fixedStream = await fixFMP4Duration(outputStream);

      // 将流转换为 Blob
      const chunks: Uint8Array[] = [];
      const reader = outputStream.getReader();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
      }

      // 清理资源
      clip.destroy();
      firstClip.destroy();
      finalClip.destroy();
      combinator.destroy();



      // 转换为 Blob
      const finalChunks: Uint8Array[] = [];
      const finalReader = fixedStream.getReader();

      while (true) {
        const { done, value } = await finalReader.read();
        if (done) break;
        finalChunks.push(value);
      }

      return new Blob(finalChunks, { type: 'video/mp4' });

    } catch (error) {
      console.error('Video trim failed:', error);
      throw new Error(`Failed to trim video: ${error.message}`);
    }
  }

  // 检查WebCodecs是否支持
  async function isWebCodecsSupported(): Promise<boolean> {
    try {
      const { Combinator } = await import('@webav/av-cliper');
      return await Combinator.isSupported();
    } catch (error) {
      console.error('Error checking WebCodecs support:', error);
      return false;
    }
  }


  return {
    download,
    loadScript,
    loginByToken,
    reportError,
    copyToClipboard,
    convertToPNG,
    normalizeImage,
    getVideoDuration,
    trimVideo,
    isWebCodecsSupported
  };
}